<template>
  <fragment>
    <div class="row">
      <loading :active.sync="isDownloading" is-full-page :zIndex="2147483639"></loading>
      <div class="col-md-12">
        <div class="row form-title d-flex align-items-center mb-4">
          <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">
            5
          </span>
          <h2 class="m-0">Documents</h2>
        </div>

        <div id="documentsContainer">
          <div id="documentContentArea" class="row col-md-12">
            <div class="k-content col-md-12">
              <label class="font-weight-bold">Path:</label>
              <span v-if="getCurrentFolderInformation">{{ getCurrentFolderInformation.folder }}</span>
            </div>
            <div class="row col-md-12 mt-2">
              <table id="tblFiles" class="table">
                <thead>
                  <tr>
                    <th scope="col" class="text-center">&nbsp;</th>
                    <th scope="col" class="text-left">File Name</th>
                    <th scope="col" class="text-left">Uploaded By</th>
                    <th scope="col" class="text-left">Uploaded On</th>
                    <th scope="col" class="text-left">Type</th>
                    <th scope="col" colspan="2" class="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody v-if="getCurrentFolderInformation">
                  <tr v-if="getCurrentFolderInformation.parentId">
                    <td class="text-center">&nbsp;</td>
                    <td class="text-left">
                      <i class="fas fa-level-up-alt" title="Up One Level" aria-hidden="true"></i>
                      <span class="sr-only">Up One Level</span>
                      <a
                        class="a-open-folder"
                        href="#"
                        @click.prevent="changeCurrentFolder(getCurrentFolderInformation.parentId)"
                      >.. Up One Folder</a>
                    </td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td class="text-left">Parent</td>
                    <td class="text-center">&nbsp;</td>
                  </tr>

                  <tr v-for="folder in getCurrentFolderInformation.folders" :key="folder.id">
                    <td class="text-center">
                        <input type="checkbox" class="checkbox-file" :value="folder.id" :checked="!!selectedFolders.find(i => i === folder.id)"
                        @change="onSelectedFolder($event.target.value)" />
                    </td>
                    <td class="text-left">
                      <i class="fas fa-folder mr-1" title="Folder" aria-hidden="true"></i>
                      <span class="sr-only">Folder</span>
                      <a class="a-open-folder" @click.prevent="changeCurrentFolder(folder.id)" href="#">{{ folder.name }}</a>
                    </td>
                    <td>{{ folder.createdBy }}</td>
                    <td>{{ folder.createdOn | datetime }}</td>
                    <td class="text-left">Folder</td>
                    <td class="text-center" style="width: 30px;">
                      <span class="folder-edit" @click="updateFolder(folder.id)">
                        <i class="fa fa-edit fa-lg" title="Rename" aria-hidden="true"></i>
                        <span class="sr-only">Rename</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 30px;">
                      <span class="folder-delete" @click="deleteFolder(folder.id)">
                        <i class="fas fa-trash-alt fa-lg" title="Delete" aria-hidden="true"></i>
                        <span class="sr-only">Delete Folder</span>
                      </span>
                    </td>
                  </tr>

                  <tr v-for="file in getCurrentFolderInformation.files" :key="file.id">
                    <td class="text-center">
                      <input type="checkbox" class="checkbox-file" :value="file.id" :checked="!!selectedFileIds.find(i => i === file.id)"
                        @change="onSelectRecord($event.target.value)" />
                    </td>
                    <td class="text-left">
                      <i class="fa fa-file" title="File" aria-hidden="true"></i>
                      <span class="sr-only">File</span>
                      {{ file.displayName }}
                    </td>
                    <td>{{ file.createdBy }}</td>
                    <td>{{ file.createdOn | datetime }}</td>
                    <td class="text-left">{{ file.extension }}</td>
                    <td class="text-center" style="width: 30px;">
                      <span class="file-download" @click="downloadFile(file.id, file.fileName)">
                        <i class="fas fa-file-download fa-lg" title="Download" aria-hidden="true"></i>
                        <span class="sr-only">Download</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 30px;">
                      <span class="file-delete" @click="deleteFile(file.id)">
                        <i class="fas fa-trash-alt fa-lg" title="Delete" aria-hidden="true"></i>
                        <span class="sr-only">Delete</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row col-md-12">
              <div class="col-md-6"> &nbsp; </div>
              <div class="col-md-6 text-right">
                <button id="btnUploadFiles" type="button" class="btn btn-primary mx-1" @click="openUploadModal">Upload</button>
                <button id="btnAddFolder" type="button" class="btn btn-success mx-1" @click="newFolder">Create Folder</button>
                <button id="btnMoveFiles" type="button" :class="['btn btn-info mx-1', { 'disabled' : !selectedFileIds.length }]"
                  :disabled="!selectedFileIds.length" @click="openMoveFilesModal">Move</button>
                <button id="btnDeleteFiles" type="button" :class="['btn btn-danger mx-1', { 'disabled' : !(selectedFileIds.length || selectedFolders.length) }]"
                  :disabled="!(selectedFileIds.length || selectedFolders.length)" @click="deleteSelectedFoldersFiles">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md d-none d-lg-block"></div>
    </div>

    <b-modal id="bv-modal-project-documents-upload" size="xl" hide-header hide-footer :no-close-on-backdrop="true">
      <project-documents-upload :projectId="project.id" :currentFolderId="currentFolderId" @file-uploaded="getRootFolderData">
      </project-documents-upload>
    </b-modal>

    <b-modal id="bv-modal-project-documents-folder-management" hide-header hide-footer :no-close-on-backdrop="true">
      <project-documents-folder-management
        :parentFolderId="currentFolderId"
        :selectedFolderId="folderManagementParams.selectedFolderId"
        :isNew="folderManagementParams.isNew"
        :isEdit="folderManagementParams.isEdit"
        @folder-created="getRootFolderData">
      </project-documents-folder-management>
    </b-modal>

    <b-modal id="bv-modal-project-documents-move-files" hide-header hide-footer :no-close-on-backdrop="true">
      <project-documents-move-files
        :selectedFileIds="selectedFileIds"
        :folderStructure="rootFolder"
        @file-uploaded="onFileUploadedProjectMove">
      </project-documents-move-files>
    </b-modal>
  </fragment>
</template>

<script>
import { FileService, FolderService } from "@/services/";
import FileDownload from "@/utils/fileDownload";
import ProjectDocumentsUpload from "@/views/project/components/project-documents-upload.vue";
import ProjectDocumentsFolderManagement from "@/views/project/components/project-documents-folder-management.vue";
import ProjectDocumentsMoveFiles from "@/views/project/components/project-documents-move-files.vue";
import Toast from "@/utils/toast";
import MessageBox from "@/utils/messagebox";

export default {
  name: "project-step-documents",
  components: {
    ProjectDocumentsUpload,
    ProjectDocumentsFolderManagement,
    ProjectDocumentsMoveFiles
  },
  props: {
    project: { type: Object, required: true },
    isNewProject: { type: Boolean, required: true },
    rootFolderId: { type: String, required: true },
    clientApiKey: { type: String }
  },
  data: function() {
    return {
      isDownloading: false,
      currentFolderId: null,
      rootFolder: null,
      selectedFileIds: [],
      selectedFolders: [],
      folderManagementParams: {
        isNew: false,
        isEdit: false,
        selectedFolderId: null
      }
    };
  },
  computed: {
    getCurrentFolderInformation() {
      if (!this.rootFolder) {
        return null;
      }

      let info = this.findFolder([this.rootFolder], this.currentFolderId, "");

      return {
        folder: info.folder,
        parentId: info.parentId,
        folders: info.children,
        files: info.files
      };
    }
  },
  mounted() {
    this.currentFolderId = this.rootFolderId;

    if (!this.isNewProject) {
      this.getRootFolderData();
    }
  },
  methods: {
    changeCurrentFolder(id) {
      this.currentFolderId = id;
      this.selectedFileIds = [];
    },
    async deleteFile(fileId) {
      let confirm = await MessageBox.confirm(
        this,
        "Remove File",
        "Are you sure you want to remove this file?"
      );

      if (confirm) {
        let payload = { projectId: this.project.id, fileId };
        FileService.deleteFile(fileId, payload).then(() => {
          this.getRootFolderData();
          Toast.success(this, "Successfully removed file");
        });
      }
    },
    async deleteFolder (folderId) {
      let message = this.$createElement('span', {}, [
        'Are you sure you want to delete this folder? Any files in this folder will also be permanently deleted.',
        this.$createElement('br'),
        this.$createElement('br'),
        this.$createElement('strong', {}, ['This action cannot be undone.'])
      ]);

      let confirm = await MessageBox.confirm(this, "Remove Folder", [message]);

      if (confirm) {
        FolderService.deleteFolder(folderId).then(() => {
          this.getRootFolderData();
          Toast.success(this, "Successfully deleted folder!");
        });
      }
    },
    async deleteSelectedFoldersFiles() {
      if (!this.selectedFolders.length && !this.selectedFileIds.length)
        return;

      let message = this.$createElement('span', {}, [
        'Are you sure you want to remove the selected files/folders? Any files in a selected folder will also be permanently deleted.',
        this.$createElement('br'),
        this.$createElement('br'),
        this.$createElement('strong', {}, ['This action cannot be undone.'])
      ]);

      let confirm = await MessageBox.confirm(this, "Remove Files/Folders", [message]);

      if(confirm) {
        Promise.all([
          this.selectedFolders.length ? FolderService.deleteSelectedFolders(this.selectedFolders) : Promise.resolve({}),
          this.selectedFileIds.length ? FileService.deleteSelectedFiles({ projectId: this.project.id, fileIds: this.selectedFileIds }) : Promise.resolve({})
        ]).then(() => {
          this.getRootFolderData();
          Toast.success(this, "Successfully deleted files/folders!");
        });

        this.selectedFolders = [];
        this.selectedFileIds = [];
      }
    },
    downloadFile(fileId, filename) {
      this.isDownloading = true;

      return FileService.downloadFile(fileId)
        .then(response => {
          FileDownload(filename, response.data);
          this.isDownloading = false;
        })
        .catch(() => {
          Toast.danger(this, "Oops! An error has occurred.");
          this.isDownloading = false;
        });
    },
    findFolder(data, id, parentFolder) {
      let top = data.find(item => item.id === id);

      if (top) {
        let currentFolder = parentFolder + "\\" + top.name;
        return { folder: currentFolder, ...top };
      }

      for (let item of data) {
        let currentFolder = parentFolder + "\\" + item.name;
        let found = this.findFolder(item.children, id, currentFolder);

        if (found) {
          return found;
        }
      }
    },
    getRootFolderData() {
      FolderService.getById(this.rootFolderId).then(response => {
        this.rootFolder = response.data;
      });
    },
    newFolder() {
      this.folderManagementParams = {
        isNew: true,
        isEdit: false,
        selectedFolderId: null
      };
      this.openManageFolderModal();
    },
    onFileUploadedProjectMove () {
      this.selectedFileIds = [];
      this.getRootFolderData();
    },
    onSelectRecord(id) {
      let pos = this.selectedFileIds.indexOf(id);
      if (pos === -1) this.selectedFileIds.push(id);
      if (pos >= 0) this.selectedFileIds.splice(pos, 1);
    },
    onSelectedFolder(id) {
      let pos = this.selectedFolders.indexOf(id);
      if (pos === -1) this.selectedFolders.push(id);
      if (pos >= 0) this.selectedFolders.splice(pos, 1);
    },
    openManageFolderModal() {
      this.$bvModal.show("bv-modal-project-documents-folder-management");
    },
    openMoveFilesModal() {
      this.$bvModal.show("bv-modal-project-documents-move-files");
    },
    openUploadModal() {
      this.$bvModal.show("bv-modal-project-documents-upload");
    },
    updateFolder(folderId) {
      this.folderManagementParams = {
        isNew: false,
        isEdit: true,
        selectedFolderId: folderId
      };
      this.openManageFolderModal();
    }
  }
};
</script>
