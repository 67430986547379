<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">New Owner</h5>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="ownerLookup">Find An Owner</label>
          <vue-bootstrap-typeahead
            id="ownerLookup"
            name="ownerLookup"
            v-model="query"
            :data="owners"
            :serializer="owner => owner.name"
            placeholder="Type a minimum 3 characters to start the search"
            :minMatchingChars="3"
            @hit="selectOwner($event)"
            ref="ownerLookup"
          >
            <template slot="append">
              <button class="btn btn-outline-danger" type="button" name="Clear Owner" title="Clear Owner" @click="clearOwner()">
                <i class="fas fa-times-circle"></i>
              </button>
            </template>

            <template slot="append">
              <button class="btn btn-outline-info" type="button" name="New Owner" title="New Owner" @click="newOwner()">
                <i class="fas fa-user-plus"></i>
              </button>
            </template>

            <template slot="suggestion" slot-scope="{ data }">
              <strong>{{ data.name }}</strong>
              <br />
              {{ getAddressDisplay(data.address) }}
              <span v-if="data.phone">
                <br />
                {{ data.phone }}
              </span>
            </template>
          </vue-bootstrap-typeahead>
          <input type="hidden" id="hasOwner" name="hasOwner" v-validate="'required:true'" v-model="hasOwner" class="form-control"
            :class="{ 'is-invalid': !isNew && errors.has('hasOwner') }" />
          <div class="invalid-feedback">Please select an owner</div>
        </div>

        <div class="form-row">
          <div class="col mb-3">
            <label for="ownerName">Name <span class="red-dot ml-3"></span></label>
            <input id="ownerName" name="ownerName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('ownerName') }"
              autocomplete="off" placeholder="Enter name" v-validate="'required'" v-model="owner.name" data-vv-as="Name" :disabled="!isNew" />
            <div class="invalid-feedback">The Name field is required.</div>
          </div>
        </div>

        <div class="form-row">
          <div class="col mb-3">
            <label for="ownerStreet">Street</label>
            <input id="ownerStreet" name="ownerStreet" type="text" class="form-control" autocomplete="off" placeholder="Enter street"
              v-model="owner.address.street" :disabled="!isNew" />
          </div>
        </div>

        <div class="form-row">
          <div class="col mb-3">
            <label for="ownerCity">City</label>
            <input id="ownerCity" name="ownerCity" type="text" class="form-control"  autocomplete="off" placeholder="Enter city"
              v-model="owner.address.city" :disabled="!isNew" />
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-6 mb-3">
            <label for="ownerCountry">Country <span class="red-dot ml-3"></span></label>
            <select id="ownerCountry" name="ownerCountry" class="custom-select" :class="{ 'is-invalid': errors.has('ownerCountry') }" :disabled="!isNew"
                v-validate="'required'" v-model="countryId" data-vv-as="Country" v-on:change="filterProvinces">
              <option v-for="country in getCountries" :key="country.id" :value="country.id">{{ country.name }}</option>
            </select>
            <div class="invalid-feedback">The Country field is required.</div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="ownerProvince">Province/State <span class="red-dot ml-3"></span></label>
            <select id="ownerProvince" name="ownerProvince" class="custom-select" :class="{ 'is-invalid': errors.has('ownerProvince') }" :disabled="!isNew"
                v-validate="'required'" v-model="owner.address.provinceId" data-vv-as="Province">
              <option v-for="province in provinceList" :key="province.id" :value="province.id">{{ province.name }}</option>
            </select>
            <div class="invalid-feedback">The Province/State field is required.</div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 mb-3">
            <label for="ownerPostalCode">Postal Code/Zip Code</label>
            <input id="ownerPostalCode" name="ownerPostalCode" type="text" class="form-control" autocomplete="off" placeholder="Enter postal code"
              v-model="owner.address.postalCode" :disabled="!isNew" />
          </div>
        </div>

        <div class="form-row">
          <div class="col mb-3">
            <label for="ownerPhone">Phone</label>
            <input id="ownerPhone" name="ownerPhone" type="text" class="form-control" autocomplete="off" placeholder="Enter phone"
              v-model="owner.phone" :disabled="!isNew" />
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox mb-2">
            <input id="ownerShowInfo" name="ownerShowInfo" type="checkbox" class="custom-control-input" v-model="owner.showInfo" :value="true" />
            <label class="custom-control-label" for="ownerShowInfo">Show Contact Info</label>
          </div>
        </div>

        <div class="form-group">
          <label for="ownerAdditionalInformation">Additional Contact Information</label>
          <textarea id="ownerAdditionalInformation" name="ownerAdditionalInformation" class="form-control"
            placeholder="Enter additional contact information" rows="5" v-model="owner.additionalInformation">
          </textarea>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";
import cloneDeep from "lodash/cloneDeep";
import AddressDisplayMixin from "@/mixins/addressDisplay";
import debounce from "lodash/debounce";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { OwnerService } from '@/services/'
import uuid from "uuid/v1";

export default {
  name: "project-owner",
  components: {
    VueBootstrapTypeahead
  },
  mixins: [AddressDisplayMixin],
  data: function() {
    return {
      query: '',
      owners: [],
      isNew: false,
      hasOwner: false,
      owner: {
        ownerId: null,
        address: {},
        showInfo: false,
        additionalInformation: null
      },
      countryId: null,
      provinceList: null
    };
  },
  computed: {
    ...mapGetters(["getProvinces", "getProjectOwner","getCountries"])
  },
  mounted: function() {
    this.owner = cloneDeep(this.getProjectOwner);

    if (!this.owner.address) {
      this.owner.address = {};
    }
    this.setCountry();
    this.filterProvinces();
  },
  watch: {
    query: debounce(
      async function (newQuery) {
        let owners = await OwnerService.getByTypeahead(encodeURIComponent(newQuery));
        this.owners = owners.data;
      }, 500, false
    ),
    'owner' : {
      immediate: true,
      deep: true,
      handler (owner) {
        this.hasOwner = !!owner.name;
      }
    }
  },
  methods: {
    selectOwner (owner) {
      this.isNew = false;
      this.owner.ownerId = owner.id;
      this.owner.name = owner.name;
      this.owner.address = owner.address || {};
      this.owner.phone = owner.phone;
    },
    clearOwner () {
      this.isNew = false;
      this.query = '';
      this.owners = [];
      this.owner.name = null;
      this.owner.displayName = null;
      this.owner.address = {};
      this.$refs.ownerLookup.handleInput('');
      this.$refs.ownerLookup.$refs.input.disabled = false;
      this.$validator.reset();
    },
    newOwner () {
      this.isNew = true;
      this.query = '';
      this.owners = [];
      this.owner.ownerId = uuid();
      this.owner.name = null;
      this.owner.displayName = null;
      this.owner.address = {};
      this.$refs.ownerLookup.handleInput('');
      this.$refs.ownerLookup.$refs.input.disabled = true;
      this.$validator.reset();
    },
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.saveProjectOwner(this.owner).then(() => {
        let message = this.$createElement('span', {}, [ 'Successfully updated!', this.$createElement('br'), 'Please remember to save your project.']);
        Toast.success(this, [message]);
        this.$bvModal.hide("bv-modal-project-owner");
      });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-project-owner");
    },
    setCountry() {
      let selectedProvince = this.getProvinces.filter((p) =>{ return p.id==this.owner.address.provinceId});
      if(selectedProvince.length >0){
        this.countryId = selectedProvince[0].countryId;
      }
      
    },
    filterProvinces() {
      this.provinceList = this.getProvinces.filter((p)=>{return p.countryId == this.countryId});
    },
    ...mapActions(["saveProjectOwner"])
  }
};
</script>
