<template>
  <fragment>
    <fieldset>
      <legend>Bidders</legend>
      <div id="project-bidder-list">
        <div class="alert alert-warning" role="alert" v-if="getProjectBids.length === 0">
          No project bids available
        </div>
        <div v-if="getProjectBids.length > 0">
          <div
            class="card mb-3"
            v-for="(bids, participantTypeId) in groupedBids"
            :key="participantTypeId"
          >
            <div class="card-body">
              <h5 class="card-title">{{ getParticipantTypeDisplay(participantTypeId) }}</h5>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" style="width: 50%">Company</th>
                    <th scope="col">Submission</th>
                    <th scope="col">Awarded</th>
                    <th scope="col" style="width: 100px">&nbsp;</th>
                    <th scope="col" style="width: 100px">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="bid in sortBidsByCompanyName(bids)" :key="bid.id">
                    <td v-if="canVisitCompanyInfo">
                      <router-link :to="'/company/edit/' + bid.company.id" class="btn btn-link btn-sm" role="button" :aria-label="'Visit ' + bid.company.name">
                        {{ bid.company.name }}
                      </router-link>
                    </td>
                    <td v-if="!canVisitCompanyInfo">
                      {{ bid.company.name }}
                    </td>
                    <td>{{ bid.submittedAmount || '---' }}</td>
                    <td>{{ bid.awardedAmount || '---' }}</td>
                    <td>
                      <a
                        role="button"
                        href="#"
                        class="d-flex justify-content-between align-items-center flex-column"
                        title="Manage"
                        @click.prevent="editProjectBid(bid.id)"
                      >
                        <img class="mb-1" src="@/assets/document.svg" alt />
                        <span class="small-grey">Manage</span>
                      </a>
                    </td>
                    <td>
                      <a
                        role="button"
                        href="#"
                        class="d-flex justify-content-between align-items-center flex-column"
                        title="Manage"
                        @click.prevent="deleteProjectBid(bid.id)"
                      >
                        <img class="mb-1" src="@/assets/document.svg" alt />
                        <span class="small-grey">Remove</span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-12 text-right">
            <button type="button" class="btn btn-primary" @click="newProjectBidder">
              <i class="fas fa-plus"></i>
              Add New Project Bid
            </button>
            <button type="button" class="btn btn-success" v-if="$permissions().isClientAdmin || $permissions().isSysAdmin" @click="emailBidders">
              <i class="fas fa-envelope"></i>
              Email Bidders
            </button>
          </div>
        </div>
      </div>
    </fieldset>
    <b-modal
      id="bv-modal-project-bid"
      size="lg"
      hide-header
      hide-footer
      :no-close-on-backdrop="true"
    >
      <project-bid @new-company="addNewCompany" :offline-companies="offlineCompanies"></project-bid>
    </b-modal>
    <b-modal
      id="bv-modal-email-bidders"
      size="lg"
      hide-header
      hide-footer
      :no-close-on-backdrop="true"
    >
      <app-send-email
        target-modal="bv-modal-email-bidders"
        :records="bidderEmailAddresses"
        :isGrouped="true"
      ></app-send-email>
    </b-modal>
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Toast from "@/utils/toast";
import MessageBox from "@/utils/messagebox";
import ProjectBid from "@/views/project/components/project-bid.vue";
import AppSendEmail from "@/components/modals/app-send-email.vue";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

import { CompanyService } from "@/services/";

export default {
  name: "project-bidders",
  components: { AppSendEmail, ProjectBid },
  data: function() {
    return {
      bidderEmailAddresses: [],
      offlineCompanies: []
    };
  },
  computed: {
    canVisitCompanyInfo () {
      return this.$permissions().isClientAdmin || this.$permissions().isSysAdmin;
    },
    groupedBids() {      
      return groupBy(this.getProjectBids, "participantTypeId");
    },
    ...mapGetters(["getCompanies", "getParticipantTypes", "getProjectBids"])
  },
  methods: {
    addNewCompany(company) {
      let _company = this.offlineCompanies.find(i => i.id === company.id);

      if (!_company) {
        this.offlineCompanies.push(company);
      }
    },
    async deleteProjectBid(id) {
      let confirm = await MessageBox.confirm(this, "Remove Bid", "Are you sure you want to remove bid?");

      if (confirm) {
        this.removeProjectBid(id).then(() => {
          Toast.success(this, "Successfully removed bid!");
        });
      }
    },
    editProjectBid(id) {
      this.fetchProjectBid(id).then(() => {
        this.$bvModal.show("bv-modal-project-bid");
      });
    },
    async emailBidders() {
      let bidderIds = this.getProjectBids.map(bidder => bidder.company.id);
      let emails = await CompanyService.getUserEmails(bidderIds);

      this.bidderEmailAddresses = emails.data
        .filter(company => company.email || company.users.length)
        .map(company => {
          let mapUser = user => ({
            name: `${user.firstName} ${user.lastName}`,
            email: user.email
          });
          let mapCompany = company => {
            return { name: company.name, email: company.email };
          };

          let users = [];
          if (company.email) users.push({ ...mapCompany(company) });
          if (company.users.length) users.push(...company.users.map(mapUser));

          return { ...mapCompany(company), users: users };
        });

      this.$bvModal.show("bv-modal-email-bidders");
    },
    getParticipantTypeDisplay(id) {
      let selectedType = this.getParticipantTypes.find(p => p.id === id);
      return selectedType ? selectedType.name : "N/A";
    },
    newProjectBidder() {
      this.resetProjectBid().then(() => {
        this.$bvModal.show("bv-modal-project-bid");
      });
    },
     sortBidsByCompanyName(bids) {
     return sortBy(bids, b => b.company.name.toLowerCase());
    },
    ...mapActions(["fetchProjectBid", "removeProjectBid", "resetProjectBid"])
  }
};
</script>
