<template>
  <fragment>
    <fieldset>
      <legend>
        Owner
        <span class="red-dot ml-3" v-if="requiredForPrebid"></span>
      </legend>

      <input type="hidden" id="hasOwner" name="hasOwner" v-model="getProject.owner" v-validate="{ required: requiredForPrebid }" data-vv-as="Owner" />

      <div class="alert alert-warning" :class="[ errors.has('hasOwner') ? 'alert-danger' : 'alert-warning']" role="alert" v-if="!getProject.owner">
        No owner has been added.
      </div>

      <div class="row mb-3" v-if="!getProject.owner">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-primary" @click="newProjectOwner">
            <i class="fas fa-plus"></i>
            Add Owner
          </button>
        </div>
      </div>
    </fieldset>

    <div v-if="getProject.owner">
      <div class="form-group row mb-2">
        <label for="displayOwnerName" class="col-sm-2 col-form-label">Name</label>
        <div class="col-sm-10">
          <input type="text" readonly disabled class="form-control-plaintext pt-0 pb-0" id="displayOwnerName" name="displayOwnerName" :value="getProject.owner.name">
        </div>
      </div>

      <div class="form-group row mb-2">
        <label for="displayOwnerName" class="col-sm-2 col-form-label">Address</label>
        <div class="col-sm-10">
          <input type="text" readonly disabled class="form-control-plaintext pt-0 pb-0" id="displayOwnerName" name="displayOwnerName"
            :value="getAddressDisplay(getProject.owner.address)">
        </div>
      </div>

      <div class="custom-control custom-checkbox mb-2">
        <input id="displayOwnerShowInfo" name="displayOwnerShowInfo" type="checkbox" class="custom-control-input" v-model="getProject.owner.showInfo"
          :value="true" readonly disabled>
        <label class="custom-control-label" for="displayOwnerShowInfo">Show Contact Info</label>
      </div>

      <div class="form-group">
        <label for="displayOwnerAdditionalInformation">Additional Contact Information</label>
        <textarea id="displayOwnerAdditionalInformation" name="ownerAdditionalInformation" class="form-control"
          placeholder="Enter additional contact information" rows="5" v-model="getProject.owner.additionalInformation" readonly disabled>
        </textarea>
      </div>

        <div class="row mb-3">
          <div class="col-md-12 text-right">
            <button type="button" class="btn btn-primary" @click="editProjectOwner">
              <i class="far fa-edit"></i>
              Edit Owner
            </button>
          </div>
        </div>
    </div>

    <b-modal id="bv-modal-project-owner" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <project-owner></project-owner>
    </b-modal>
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddressDisplayMixin from "@/mixins/addressDisplay";
import { isDraftMode, isPreBid, isPrivateProject, requiredForPrebid } from "@/mixins/projectStatuses";
import ProjectOwner from "@/views/project/components/project-owner.vue"

export default {
  name: "project-owners",
  inject: ["$validator"],
  mixins: [AddressDisplayMixin, isDraftMode, isPreBid, isPrivateProject, requiredForPrebid],
  components: { ProjectOwner },
  props: {
    project: { type: Object, required: true }
  },
  computed: {
    ...mapGetters(["getProject", "getOwners"])
  },
  methods: {
    newProjectOwner() {
      this.resetProjectOwner().then(() => {
         this.$bvModal.show("bv-modal-project-owner");
      });
    },
    editProjectOwner () {
      this.fetchProjectOwner().then(() => {
        this.$bvModal.show("bv-modal-project-owner");
      });
    },
    ...mapActions(["fetchProjectOwner", "resetProjectOwner"])
  }
};
</script>
