<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Send Email</h5>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="cc">To <span class="red-dot ml-3"></span></label>
          <multiselect
            name="to"
            v-model="email.to"
            :options="records"
            :multiple="true"
            track-by="email"
            label="name"
            :group-values="getGroupValue"
            :group-label="getGroupLabel"
            :group-select="true"
            v-validate="'required'"
            :class="{ 'is-invalid': errors.has('to') }"
            data-vv-as="To"
          ></multiselect>
          <span class="invalid-feedback" v-if="errors.has('to')">The To field is required</span>
        </div>

        <div class="form-group">
          <label for="cc">CC</label>
          <multiselect
            name="cc"
            v-model="email.cc"
            :options="records"
            :multiple="true"
            track-by="email"
            label="name"
          ></multiselect>
        </div>

        <div class="form-group">
          <label for="bcc">BCC</label>
          <multiselect
            name="bcc"
            v-model="email.bcc"
            :options="records"
            :multiple="true"
            track-by="email"
            label="name"
          ></multiselect>
        </div>

        <div class="form-group" v-if="enableIncludeMe">
          <div class="custom-control custom-checkbox">
            <input id="includeMe" name="includeMe" type="checkbox" class="custom-control-input" :value="true" v-model="email.includeMe">
            <label class="custom-control-label" for="includeMe">Send me a copy of this email</label>
          </div>
        </div>

        <div class="form-group">
          <label for="subject">
            Subject
            <span class="red-dot ml-3"></span>
          </label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.has('subject') }"
            id="subject"
            name="subject"
            autocomplete="off"
            v-validate="'required'"
            v-model="email.subject"
            data-vv-as="Subject"
          />
          <span class="invalid-feedback" v-if="errors.has('subject')">The Subject field is required</span>
        </div>

        <div class="form-group">
          <label for="body">
            Body
            <span class="red-dot ml-3"></span>
          </label>
          <app-editor
            id="body"
            name="body"
            class="w-100"
            :class="{ 'is-invalid': errors.has('body') }"
            v-model="email.body"
            v-validate="'required'"
            data-vv-as="Body"
          ></app-editor>
          <span class="invalid-feedback" v-if="errors.has('body')">The Body field is required</span>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary ld-ext-right" :class="{ 'running' : isSaving }" :disabled="isSaving">
          <span v-if="!isSaving"> Send Email </span>
          <span v-else> Sending </span>
          <div class="ld ld-ring ld-spin"></div>
        </button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import Toast from "@/utils/toast";
import { EmailService } from "@/services/";

export default {
  name: "app-send-email",
  components: { AppEditor: () => import("@/components/app-editor") },
  props: {
    records: { type: Array, default: () => [] },
    targetModal: { type: String, required: true },
    isGrouped: { type: Boolean, default: () => false },
    groupValue: { type: String, default: () => 'users' },
    groupLabel: { type: String, default: () => 'name' },
    autoSelect: { type: Boolean, default: () => false },
    enableIncludeMe: { type: Boolean, default: () => false },
    subject: { type: String },
    body: { type: String },
  },
  data: function() {
    return {
      email: {
        to: [],
        cc: [],
        bcc: [],
        includeMe: false,
        subject: null,
        body: null
      },
      isSaving: false
    };
  },
  computed: {
    getGroupValue () { return this.isGrouped ? this.groupValue : '' },
    getGroupLabel () { return this.isGrouped ? this.groupLabel : '' },
  },
  methods: {
    closeModal() {
      if (this.targetModal) this.$bvModal.hide(this.targetModal);
    },
    async onSubmit() {
      this.isSaving = true;
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        this.isSaving = false;
        return;
      }

      let model = {
        to: this.email.to.map(p => p.email),
        cc: this.email.cc.map(p => p.email),
        bcc: this.email.bcc.map(p => p.email),
        includeMe: this.email.includeMe,
        subject: this.email.subject,
        body: this.email.body
      };

      EmailService.sendIndividual(model)
        .then(() => {
          Toast.success(this, "Successfully sent!");
          this.closeModal();
        })
        .catch(() => {
          Toast.danger(this, "Oops! An error has occured.");
        });
    }
  },
  created () {
    if (this.autoSelect) this.email.to = this.records;
    if (this.subject) this.email.subject = this.subject;
    if (this.body) this.email.body = this.body;
  },
};
</script>
