<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h4 class="modal-title" v-if="isNew">New Folder</h4>
        <h4 class="modal-title" v-else>Manage Folder</h4>
      </div>

      <div v-if="isLoading">
        <div class="modal-body">Loading...</div>
      </div>

      <div v-if="!isLoading">
        <div class="modal-body">
          <div class="alert alert-danger" v-if="validationErrors.length">
            <span v-for="error in validationErrors" :key="error">{{ error }}</span>
          </div>

          <div class="form-group" v-if="isEdit">
            <label for="currentFolderName">Current Folder Name:</label>
            <input
              type="text"
              readonly
              class="form-control"
              id="currentFolderName"
              name="currentFolderName"
              :value="selectedFolder.name"
            />
          </div>

          <div class="form-group">
            <label for="folderName" v-if="isNew">Name:</label>
            <label for="folderName" v-else>Updated Name:</label>
            <input
              type="text"
              id="folderName"
              name="folderName"
              autocomplete="off"
              :class="['form-control', { 'is-invalid': errors.has('folderName') }]"
              v-model="folderName"
              v-validate="'required'"
              data-vv-as="Name"
            />
            <div class="invalid-feedback">Name is required</div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="submit" class="btn btn-primary ld-ext-right" :class="{ 'running' : isSaving }" :disabled="isSaving">
            <i class="far fa-save"></i>
            <span class="ml-2"> Save </span>
            <div class="ld ld-ring ld-spin"></div>
          </button>
          <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
        </div>
      </div>
    </form>
  </fragment>
</template>

<script>
import { FolderService } from "@/services/";
import Toast from "@/utils/toast";

export default {
  name: "project-documents-folder-managment",
  props: {
    parentFolderId: { type: String, required: true },
    selectedFolderId: {
      type: String,
      validator: prop => typeof prop === "string" || prop === null
    },
    isNew: { type: Boolean, required: true },
    isEdit: { type: Boolean, required: true }
  },
  data: function() {
    return {
      isLoading: true,
      isSaving: false,
      validationErrors: [],
      selectedFolder: {
        name: null
      },
      folderName: ""
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("bv-modal-project-documents-folder-management");
    },
    handleRequest() {
      let params = {
        name: this.folderName,
        parentId: this.selectedFolder.parentId || this.parentFolderId
      };

      if (this.selectedFolder.id) {
        params.id = this.selectedFolder.id;
      }

      if (this.isNew) {
        return FolderService.createFolder(params);
      } else if (this.isEdit) {
        return FolderService.updateFolder(this.selectedFolder.id, params);
      }
    },
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.isSaving = true;

      this.handleRequest()
        .then(() => {
          this.$emit("folder-created");
          Toast.success(this, "Successfully saved folder!");
          this.closeModal();
        })
        .catch(err => {
          if (err.response.data.message && err.response.data.modelState) {
            this.validationErrors = [...err.response.data.modelState.fileIds];
          }
        })
        .finally(() => this.isSaving = false);
    }
  },
  mounted() {
    if (this.selectedFolderId) {
      FolderService.getById(this.selectedFolderId).then(response => {
        this.selectedFolder = response.data;
        this.isLoading = false;
      });
    } else {
      this.isLoading = false;
    }
  }
};
</script>
