<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h4 class="modal-title">Add Project Address</h4>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="address">Street</label>
          <input id="address" name="address" type="text" class="form-control" autocomplete="off" placeholder="Enter site address" v-model="address.street">
        </div>

        <div class="form-group">
          <label for="city">City</label>
          <input id="city" name="city" type="text" class="form-control" autocomplete="off" placeholder="City" v-model="address.city">
        </div>

        <div class="form-row">
          <div class="col-lg-6 mb-3">
            <label for="country">Country</label>
            <select id="country" name="country" class="custom-select" :class="{ 'is-invalid': errors.has('country') }"
                v-validate="'required'" data-vv-as="Country" v-model="countryId" v-on:change="filterProvinces()">
              <option v-for="country in getCountries" :key="country.id" :value="country.id">{{ country.name }}</option>
            </select>
            <div class="invalid-feedback"> The Country field is required </div>
          </div>
          <div class="col-lg-6 mb-3">
            <label for="province">Province/State</label>
            <select id="province" name="province" class="custom-select" :class="{ 'is-invalid': errors.has('province') }"
                v-validate="'required'" v-model="address.provinceId" data-vv-as="Province">
              <option v-for="province in provinceList" :key="province.id" :value="province.id" :data-countryid="province.countryId">{{ province.name }}</option>
            </select>
            <div class="invalid-feedback"> The Province/State field is required </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-6 mb-3">
            <label for="postalCode">Postal Code/Zip Code</label>
            <input id="postalCode" name="postalCode" type="text" class="form-control" autocomplete="off" placeholder="Postal code" v-model="address.postalCode">
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";

export default {
  name: "project-address",
  data: function() {
    return {
      address: {
        id: null,
        street: null,
        city: null,
        provinceId: null,
        postalCode: null
      },
      countryId: null,
      provinceList: null
    };
  },
  computed: {
    ...mapGetters(["getProjectAddress", "getProvinces","getCountries"])
  },
  mounted: function() {
    this.address = { ...this.getProjectAddress };
    this.setCountry();
    this.filterProvinces();
    this.$validator.reset();
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.saveProjectAddress(this.address).then(() => {
        let message = this.$createElement('span', {}, [ 'Successfully updated!', this.$createElement('br'), 'Please remember to save your project.']);
        Toast.success(this, [message]);
        this.$bvModal.hide("bv-modal-project-address");
      });
    },
    setCountry() {
        let selectedProvince = this.getProvinces.filter((p) =>{ return p.id==this.address.provinceId});
        if(selectedProvince.length >0){
          this.countryId = selectedProvince[0].countryId;
        }
        
    },
    filterProvinces() {
      this.provinceList = this.getProvinces.filter((p)=>{return p.countryId == this.countryId});
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-project-address");
    },
    ...mapActions(["saveProjectAddress"])
  }
};
</script>
