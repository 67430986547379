<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">New Contact</h5>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="companyName">Company <span class="red-dot ml-3"></span></label>
          <input id="companyName" name="companyName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('companyName') }"
            autocomplete="off" placeholder="Enter company name" v-model="contact.companyName" v-validate="'required'" data-vv-as="Company">
          <div class="invalid-feedback">The Company field is required</div>
        </div>

        <div class="custom-control custom-checkbox mb-2">
          <input id="showInfo" name="showInfo" type="checkbox" class="custom-control-input" v-model="contact.showInfo" :value="true">
          <label class="custom-control-label" for="showInfo">Show Contact Info</label>
        </div>

        <div class="form-group">
          <label for="address">Street</label>
          <input id="address" name="address" type="text" class="form-control" autocomplete="off" placeholder="Enter site address" v-model="contact.address.street">
        </div>

        <div class="form-group">
          <label for="city">City</label>
          <input id="city" name="city" type="text" class="form-control" autocomplete="off" placeholder="City" v-model="contact.address.city">
        </div>

        <div class="form-row">
          <div class="col-lg-6 mb-3">
            <label for="country">Country <span class="red-dot ml-3"></span></label>
            <select id="country" name="country" class="custom-select" :class="{ 'is-invalid': errors.has('country') }"
                v-validate="'required'" v-model="countryId" data-vv-as="Country" v-on:change="filterProvinces">
              <option v-for="country in getCountries" :key="country.id" :value="country.id">{{ country.name }}</option>
            </select>
            <div class="invalid-feedback"> The Country field is required </div>
          </div>
          <div class="col-lg-6 mb-3">
            <label for="province">Province/State <span class="red-dot ml-3"></span></label>
            <select id="province" name="province" class="custom-select" :class="{ 'is-invalid': errors.has('province') }"
                v-validate="'required'" v-model="contact.address.provinceId" data-vv-as="Province">
              <option v-for="province in provinceList" :key="province.id" :value="province.id">{{ province.name }}</option>
            </select>
            <div class="invalid-feedback"> The Province/State field is required </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-6 mb-3">
            <label for="postalCode">Postal Code/Zip Code</label>
            <input id="postalCode" name="postalCode" type="text" class="form-control" autocomplete="off" placeholder="Postal code" v-model="contact.address.postalCode">
          </div>
        </div>

        <div class="form-row">
          <div class="col-lg-6 mb-3">
            <label for="phone">Phone</label>
            <input id="phone" name="phone" type="text" class="form-control" autocomplete="off" placeholder="Phone" v-model="contact.phone">
          </div>

          <div class="col-lg-6 mb-3">
            <label for="contactType">Type <span class="red-dot ml-3"></span></label>
            <select id="contactType" name="contactType" class="custom-select" :class="{ 'is-invalid': errors.has('contactType') }"
                v-model="contact.contactTypeId" v-validate="'required'" data-vv-as="Type">
              <option v-for="contactType in getContactTypes" :key="contactType.id" :value="contactType.id">{{ contactType.name }}</option>
            </select>
            <div class="invalid-feedback">The Type field is required</div>
          </div>
        </div>

        <div class="form-group">
          <label for="additionalInformation">Additional Contact Information</label>
          <textarea id="additionalInformation" name="additionalInformation" class="form-control" rows="3"
            placeholder="Enter contact information" v-model="contact.additionalInformation">
          </textarea>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "project-contact",
  data: function() {
    return {
      contact: {
        id: null,
        companyName: null,
        showInfo: false,
        address: {
          street: null,
          city: null,
          postalCode: null,
          provinceId: null
        },
        phone: null,
        contactTypeId: null,
        additionalInformation: null
      },
      countryId: null,
      provinceList: null
    };
  },
  computed: {
    ...mapGetters(["getContactTypes", "getProjectContact", "getProvinces","getCountries"])
  },
  mounted: function() {
    this.contact = cloneDeep(this.getProjectContact);
    this.setCountry();
    this.filterProvinces();
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.saveProjectContact(this.contact).then(() => {
        let message = this.$createElement('span', {}, [ 'Successfully updated!', this.$createElement('br'), 'Please remember to save your project.']);
        Toast.success(this, [message]);
        this.$bvModal.hide("bv-modal-project-contact");
      });
    },
    setCountry() {
      let selectedProvince = this.getProvinces.filter((p) =>{ return p.id==this.contact.address.provinceId});
      if(selectedProvince.length >0){
        this.countryId = selectedProvince[0].countryId;
      }
      
    },
    filterProvinces() {
      this.provinceList = this.getProvinces.filter((p)=>{return p.countryId == this.countryId});
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-project-contact");
    },
    ...mapActions(["saveProjectContact"])
  }
};
</script>
