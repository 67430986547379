<template>
  <fragment>
    <div class="row">
      <div class="col-md-12">
        <div class="row form-title d-flex align-items-center mb-4">
          <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">7</span>
          <h2 class="m-0">Follow Up Tasks</h2>
        </div>

        <div class="follow-up-task-list">
          <div class="alert alert-warning" role="alert" v-if="getProjectTasks.length === 0">
            No follow up tasks available
          </div>

          <table class="table" v-if="getProjectTasks.length > 0">
            <thead>
              <tr>
                <th scope="col">Task</th>
                <th scope="col">Due Date</th>
                <th scope="col" style="width: 100px">&nbsp;</th>
                <th scope="col" style="width: 100px">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr class="follow-up-task-item" v-for="task in sortedTasks" :key="task.id">
                <td>
                  <div class="custom-control custom-checkbox mb-3">
                    <input :id="'task-completed-flag-' + task.id" :name="'task-completed-flag-' + task.id" type="checkbox" class="custom-control-input"
                      :checked="task.isCompleted" :value="task.id" @change="updateTaskCompletedFlag($event)">
                    <label class="custom-control-label" :for="'task-completed-flag-' + task.id">{{ task.text }}</label>
                  </div>
                </td>
                <td>{{ task.dueDate | datetime('MMM dd, yyyy p') }} {{ selectedTimeZone.code }}</td>
                <td>
                  <a role="button" href="#" class="d-flex justify-content-between align-items-center flex-column" title="Manage" @click.prevent="editProjectTask(task.id)">
                    <img class="mb-1" src="@/assets/document.svg" alt />
                    <span class="small-grey">Manage</span>
                  </a>
                </td>
                <td>
                  <a role="button" href="#" class="d-flex justify-content-between align-items-center flex-column" title="Manage" @click.prevent="deleteProjectTask(task.id)">
                    <img class="mb-1" src="@/assets/document.svg" alt />
                    <span class="small-grey">Remove</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row mb-3">
          <div class="col-md-12 text-right">
            <button type="button" class="btn btn-primary" @click="newProjectTask">
              <i class="fas fa-plus"></i>
              Add New Task
            </button>
          </div>
        </div>
      </div>
      <div class="col-md d-none d-lg-block"></div>
    </div>
    <b-modal id="bv-modal-project-task" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <project-task :timeZoneCode="selectedTimeZone.code"></project-task>
    </b-modal>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import sortBy from "lodash/sortBy";
import Toast from "@/utils/toast";
import MessageBox from "@/utils/messagebox";
import ProjectTask from "@/views/project/components/project-task.vue";

export default {
  name: "project-step-tasks",
  components: { ProjectTask },
  props: ["selectedTimeZone"],
  computed: {
    ...mapGetters(["getProjectTasks"]),
    sortedTasks() {
      return sortBy(this.getProjectTasks, [
        function(task) {
          return task.isCompleted;
        },
        "dueDate"
      ]);
    }
  },
  methods: {
    newProjectTask() {
      this.resetProjectTask().then(() => {
        this.$bvModal.show("bv-modal-project-task");
      });
    },
    editProjectTask(id) {
      this.fetchProjectTask(id).then(() => {
        this.$bvModal.show("bv-modal-project-task");
      });
    },
    updateTaskCompletedFlag(e) {
      let id = e.target.value;
      let completed = e.target.checked;

      this.updateProjectTaskCompletedFlag({ id, completed });
    },
    async deleteProjectTask(id) {
      let confirm = await MessageBox.confirm(
        this,
        "Remove Task",
        "Are you sure you want to remove task"
      );

      if (confirm) {
        this.removeProjectTask(id).then(() => {
          Toast.success(this, "Successfully removed task!");
        });
      }
    },
    ...mapActions([
      "fetchProjectTask",
      "resetProjectTask",
      "updateProjectTaskCompletedFlag",
      "removeProjectTask"
    ])
  }
};
</script>
