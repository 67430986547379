<template>
  <fragment>
    <div class="row">
      <div class="col-md-12">
        <div class="row form-title d-flex align-items-center mb-4">
          <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">4</span>
          <h2 class="m-0">Project Contacts</h2>
        </div>

        <project-owners :project.sync="project"></project-owners>
        <hr />
        <project-contacts></project-contacts>
        <hr />
        <project-bidders></project-bidders>

        <div class="form-group">
          <label for="externalPlanHolders">External Plan Holders</label>

          <textarea id="externalPlanHolders" name="externalPlanHolders" class="form-control" placeholder="Enter additional external plan holders"
            rows="10" v-model="project.externalPlanHolders"></textarea>
        </div>

      </div>
      <div class="col-md d-none d-lg-block"></div>
    </div>
  </fragment>
</template>

<script>
import ProjectOwners from "@/views/project/components/project-owners.vue";
import ProjectContacts from "@/views/project/components/project-contacts.vue";
import ProjectBidders from "@/views/project/components/project-bidders.vue";

export default {
  name: "project-step-contacts",
  components: { ProjectOwners, ProjectContacts, ProjectBidders },
  inject: ["$validator"],
  props: ["project"]
};
</script>
