<template>
  <fragment>
    <div id="site-address-list">
      <div class="alert alert-warning" role="alert" v-if="getProjectAddresses.length === 0">
        No project addresses available
      </div>

      <table class="table" v-if="getProjectAddresses.length > 0">
        <thead>
          <tr>
            <th scope="col">Address</th>
            <th scope="col">City</th>
            <th scope="col">Province/State</th>
            <th scope="col">Postal Code/Zip Code</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="address in getProjectAddresses" :key="address.id">
            <td>{{ address.street || '---' }}</td>
            <td>{{ address.city || '---' }}</td>
            <td>{{ getProvinceName(address.provinceId) }}</td>
            <td>{{ address.postalCode || '---' }}</td>
            <td>
              <a role="button" href="#" class="d-flex justify-content-between align-items-center flex-column" title="Manage" @click.prevent="editProjectAddress(address.id)">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Manage</span>
              </a>
            </td>
            <td>
              <a role="button" href="#" class="d-flex justify-content-between align-items-center flex-column" title="Manage" @click.prevent="deleteProjectAddress(address.id)">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Remove</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row mb-3">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-primary" @click="newProjectAddress">
            <i class="fas fa-plus"></i>
            Add New Project Address
          </button>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-project-address" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <project-address></project-address>
    </b-modal>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";
import MessageBox from "@/utils/messagebox";
import ProjectAddress from "@/views/project/components/project-address.vue";

export default {
  name: "project-addresses",
  components: { ProjectAddress },
  computed: {
    ...mapGetters(["getProjectAddresses", "getProvinces"])
  },
  methods: {
    getProvinceName: function(id) {
      let province = this.getProvinces.find(province => province.id === id);
      return (province && province.name) || "";
    },
    newProjectAddress() {
      this.resetProjectAddress().then(() => {
        this.$bvModal.show("bv-modal-project-address");
      });
    },
    editProjectAddress(id) {
      this.fetchProjectAddress(id).then(() => {
        this.$bvModal.show("bv-modal-project-address");
      });
    },
    async deleteProjectAddress(id) {
      let confirm = await MessageBox.confirm(
        this,
        "Remove Address",
        "Are you sure you want to remove address"
      );

      if (confirm) {
        this.removeProjectAddress(id).then(() => {
          Toast.success(this, "Successfully removed address!");
        });
      }
    },
    ...mapActions([
      "fetchProjectAddress",
      "resetProjectAddress",
      "removeProjectAddress"
    ])
  }
};
</script>
