<template>
  <fragment>
    <div class="modal-header">
      <h4 class="modal-title">Move Files</h4>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger" v-if="validationErrors.length">
        <span v-for="error in validationErrors" :key="error">{{ error }}</span>
      </div>

      <v-tree :scope-data="formattedFolderStructure">
        <ul slot-scope="folder">
          <li v-for="child in folder" :key="child.id">
            <tree-leaf
              :info="child"
              :selectedFolderId="selectedFolderId"
              @select-tree-node="selectNode"
            ></tree-leaf>
          </li>
        </ul>
      </v-tree>
    </div>

    <div class="modal-footer">
      <button
        type="submit"
        :class="['btn btn-primary', { 'disabled' : !selectedFolderId }]"
        :disabled="!selectedFolderId"
        @click="moveFiles"
      >Confirm</button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
    </div>
  </fragment>
</template>

<style scoped>
ul,
li {
  list-style-type: none;
}
</style>

<script>
import { FileService } from "@/services/";
import Toast from "@/utils/toast";
import VTree from "vue-vtree";

let TreeLeaf = {
  template: `
    <fragment>
    <div>
      <span :class="['fa', info.children && info.children.length ? isCollapsed ? 'fa-caret-right' : 'fa-caret-down' : '']" aria-hidden="true" @click="expandLeaf"></span>
      <span :class="['p-1 ml-1', selectedFolderId === info.id ? 'border border-secondary' : '']" @click="selectNode(info.id)">
        <span class="fa fa-folder" aria-hidden="true"></span>
        {{ info.name }}
      </span>
    </div>
    <v-tree v-if="!isCollapsed && info.children" :scope-data="info.children"></v-tree>
    </fragment>
  `,
  props: {
    info: { type: Object, required: true },
    selectedFolderId: {
      required: true,
      validator: prop => typeof prop === "string" || prop === null
    }
  },
  components: {
    VTree
  },
  data: function() {
    return {
      isCollapsed: true
    };
  },
  methods: {
    expandLeaf() {
      this.isCollapsed = !this.isCollapsed;
    },
    selectNode(id) {
      this.isCollapsed = false;
      this.$emit("select-tree-node", id);
    }
  }
};

export default {
  name: "project-documents-move-files",
  props: {
    selectedFileIds: { type: Array, required: true },
    folderStructure: { type: Object, require: true }
  },
  components: { VTree, TreeLeaf },
  data: function() {
    return {
      validationErrors: [],
      selectedFolderId: null
    };
  },
  computed: {
    formattedFolderStructure() {
      return [this.folderStructure];
    }
  },
  methods: {
    selectNode(id) {
      this.selectedFolderId = id;
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-project-documents-move-files");
    },
    moveFiles() {
      let params = {
        folderId: this.selectedFolderId,
        fileIds: this.selectedFileIds
      };

      FileService.moveFiles(params)
        .then(() => {
          this.$emit("file-uploaded");
          Toast.success(this, "Successfully move files!");
          this.closeModal();
        })
        .catch(err => {
          if (err.response.data.message && err.response.data.modelState) {
            this.validationErrors = [...err.response.data.modelState.fileIds]
          }
        });
    }
  }
};
</script>
