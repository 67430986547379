<template>
<fragment>
  <div class="row">
    <div class="col-md-12">
      <div class="row form-title d-flex align-items-center mb-4">
        <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">6</span>
        <div class="d-flex flex-column">
          <h2 class="mb-0">{{ getNotesHeading() }}</h2>
          <div v-if="project.isPrivate" class="small-grey">These notes will not be displayed to members.</div>
        </div>
      </div>

      <div class="alert alert-warning" role="alert" v-if="getProjectNotes.length === 0">
        No project notes available
      </div>

      <div id="admin-note-list" v-if="getProjectNotes.length > 0">
        <div class="admin-note-item col-md-12" v-for="note in getProjectNotes" :key="note.id">
          <div class="row">
            <h4 class="title-text">{{ note.name }}</h4>
            <a href="#" title="Edit Note" style="margin-left: auto" @click.prevent="editProjectNote(note.id)">Edit</a>
          </div>
          <div class="w-100 rounded p-1 px-2">
            <span style="white-space: pre-line;">{{ note.text }}</span>
          </div>
          <h5 class="date-text">{{ note.createdOn | datetime('MMM dd, yyyy p') }} {{ getClientTimeZone.code }}</h5>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-primary" @click="newProjectNote">
            <i class="fas fa-plus"></i>
            Add New Note
          </button>
        </div>
      </div>
    </div>
    <div class="col-md d-none d-lg-block"></div>

    <div class="col-md-12" v-if="showPrebidNotes">
      <h3 class="mb-3">Prebid Notes</h3>

      <div class="alert alert-warning" role="alert" v-if="getProjectPrebidNotes.length === 0">
        No prebid notes available
      </div>

      <div id="admin-note-list" v-if="getProjectPrebidNotes.length > 0">
        <div class="admin-note-item col-md-12" v-for="note in getProjectPrebidNotes" :key="note.id">
          <div class="row">
            <h4 class="title-text">{{ note.name }}</h4>
            <a href="#" title="Edit Note" style="margin-left: auto" @click.prevent="editProjectPrebidNote(note.id)">Edit</a>
          </div>
          <div class="w-100 rounded p-1 px-2">
            <span style="white-space: pre-line;">{{ note.text }}</span>
          </div>
          <h5 class="date-text">{{ note.createdOn | datetime('MMM dd, yyyy p') }} {{ getClientTimeZone.code }}</h5>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-primary" @click="newProjectPrebidNote">
            <i class="fas fa-plus"></i>
            Add New Prebid Note
          </button>
        </div>
      </div>
    </div>
    <div class="col-md d-none d-lg-block"></div>
  </div>

  <b-modal id="bv-modal-project-note" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
    <project-note></project-note>
  </b-modal>

  <b-modal id="bv-modal-project-prebid-note" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
    <project-prebid-note></project-prebid-note>
  </b-modal>
</fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { TenderStages } from "@/utils/enums";

import ProjectNote from "@/views/project/components/project-note.vue";
import ProjectPrebidNote from "@/views/project/components/project-prebid-note.vue";

export default {
  name: "project-notes",
  components: { ProjectNote, ProjectPrebidNote },
  props: ["project"],
  computed: {
    showPrebidNotes () {
      return this.project.tenderStageId === TenderStages.PREBID || (this.$permissions().isSysAdmin || this.$permissions().isClientAdmin);
    },
    ...mapGetters(["getProjectNotes", "getProjectPrebidNotes", "getClientTimeZone"])
  },
    methods: {
      getNotesHeading() {
        return `${!this.project.isPrivate ? "Admin " : "" }Notes`;
      },
    newProjectNote() {
      this.resetProjectNote().then(() => {
        this.$bvModal.show("bv-modal-project-note");
      });
    },
    newProjectPrebidNote() {
      this.resetProjectPrebidNote().then(() => {
        this.$bvModal.show("bv-modal-project-prebid-note");
      });
    },
    editProjectNote(id) {
      this.fetchProjectNote(id).then(() => {
        this.$bvModal.show("bv-modal-project-note");
      });
    },
    editProjectPrebidNote(id) {
      this.fetchProjectPrebidNote(id).then(() => {
        this.$bvModal.show("bv-modal-project-prebid-note");
      });
    },
    ...mapActions(["fetchProjectNote", "fetchProjectPrebidNote", "resetProjectNote", "resetProjectPrebidNote"])
  }
};
</script>
