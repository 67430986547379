<template>
  <fragment>
    <div id="site-meeting-list">
      <h3 class="mb-3">Site Meetings</h3>
      <div class="alert alert-warning" role="alert" v-if="getProjectSiteMeetings.length === 0">
        No site meetings available
      </div>

      <table class="table" v-if="getProjectSiteMeetings.length > 0">
        <thead>
          <tr>
            <th scope="col" style="width: 225px">Meeting Date</th>
            <th scope="col">Site Meeting Information</th>
            <th scope="col">Priority</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="meeting in getProjectSiteMeetings" :key="meeting.id">
            <td>{{ (meeting.date || '---') | datetime('MMM dd, yyyy p') }} {{ selectedTimeZone.code }}</td>
            <td> <span style="white-space: pre-wrap;">{{ meeting.text }}</span> </td>
            <td> {{ meeting.priority | priority }} </td>
            <td>
              <a role="button" href="#" class="d-flex justify-content-between align-items-center flex-column"
                  title="Manage" @click.prevent="editProjectSiteMeeting(meeting.id)">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Manage</span>
              </a>
            </td>
            <td>
              <a role="button" href="#" class="d-flex justify-content-between align-items-center flex-column"
                  title="Manage" @click.prevent="deleteProjectSiteMeeting(meeting.id)">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Remove</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row mb-3">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-primary" @click="newProjectSiteMeeting">
            <i class="fas fa-plus"></i>
            Add New Site Meeting
          </button>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-project-site-meeting" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <project-site-meeting :timeZoneCode="selectedTimeZone.code"></project-site-meeting>
    </b-modal>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";
import MessageBox from "@/utils/messagebox";
import ProjectSiteMeeting from "@/views/project/components/project-site-meeting.vue";

export default {
  name: "project-site-meetings",
  components: { ProjectSiteMeeting },
  props: ["selectedTimeZone"],
  computed: {
    ...mapGetters(["getProjectSiteMeetings"])
  },
  filters: {
    priority: function (value) {
      if (!value) return 'Optional';

      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
  },
  methods: {
    newProjectSiteMeeting() {
      this.resetProjectSiteMeeting().then(() => {
        this.$bvModal.show("bv-modal-project-site-meeting");
      });
    },
    editProjectSiteMeeting(id) {
      this.fetchProjectSiteMeeting(id).then(() => {
        this.$bvModal.show("bv-modal-project-site-meeting");
      });
    },
    async deleteProjectSiteMeeting(id) {
      let confirm = await MessageBox.confirm(this, "Remove Site Meeting", "Are you sure you want to remove site meeting");

      if (confirm) {
        this.removeProjectSiteMeeting(id).then(() => {
          Toast.success(this, "Successfully removed site meeting!");
        });
      }
    },
    ...mapActions([
      "fetchProjectSiteMeeting",
      "resetProjectSiteMeeting",
      "removeProjectSiteMeeting"
    ])
  }
};
</script>
