<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h4 class="modal-title">Add Prebid Note</h4>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="createdBy">Name</label>
          <input id="createdBy" name="createdBy" type="text" class="form-control-plaintext" :value="note.name" readonly>
        </div>

        <div class="form-group">
          <label for="details">Text <span class="red-dot ml-3"></span></label>
          <textarea id="details" name="details" class="form-control" :class="{ 'is-invalid': errors.has('details') }"
            rows="5" placeholder="Enter text" v-validate="'required'" v-model="note.text" data-vv-as="Text">
          </textarea>
          <div class="invalid-feedback">The Text field is required</div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import Toast from "@/utils/toast";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "project-prebid-note",
  data: function() {
    return {
      note: {
        id: null,
        name: null,
        text: null
      }
    };
  },
  computed: {
    ...mapGetters(["getProjectPrebidNote"])
  },
  mounted: function() {
    this.note = { ...this.getProjectPrebidNote };

    this.$validator.reset();
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      if (!this.note.createdOn) {
        this.note.createdOn = new Date().toISOString();
      }

      this.saveProjectPrebidNote(this.note).then(() => {
        let message = this.$createElement('span', {}, [ 'Successfully updated!', this.$createElement('br'), 'Please remember to save your project.']);
        Toast.success(this, [message]);
        this.$bvModal.hide("bv-modal-project-prebid-note");
      });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-project-prebid-note");
    },
    ...mapActions(["saveProjectPrebidNote"])
  }
};
</script>
