<template>
  <fragment>
    <fieldset>
      <legend>Project Contacts</legend>
      <div id="project-contact-list">
        <div class="alert alert-warning" role="alert" v-if="getProjectContacts.length === 0">
          No project contacts available
        </div>

        <table class="table" v-if="getProjectContacts.length > 0">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Address</th>
              <th scope="col">Phone</th>
              <th scope="col">Type</th>
              <th scope="col">Show Contact Info</th>
              <th scope="col" style="width: 100px">&nbsp;</th>
              <th scope="col" style="width: 100px" v-if="$permissions().hasDeleteProjectPermission">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="contact in getProjectContacts" :key="contact.id">
              <td>{{ contact.companyName }}</td>
              <td>{{ getAddressDisplay(contact.address) }}</td>
              <td>{{ contact.phone }}</td>
              <td>{{ getContactTypeDisplay(contact.contactTypeId) }}</td>
              <td>{{ contact.showInfo ? "Yes" : "No" }}</td>
              <td>
                <a role="button" href="#" class="d-flex justify-content-between align-items-center flex-column"
                    title="Manage" @click.prevent="editProjectContact(contact.id)">
                  <img class="mb-1" src="@/assets/document.svg" alt />
                  <span class="small-grey">Manage</span>
                </a>
              </td>
              <td>
                <a role="button" href="#" class="d-flex justify-content-between align-items-center flex-column"
                    title="Manage" @click.prevent="deleteProjectContact(contact.id)">
                  <img class="mb-1" src="@/assets/document.svg" alt />
                  <span class="small-grey">Remove</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mb-3">
          <div class="col-md-12 text-right">
            <button type="button" class="btn btn-primary" @click="newProjectContact">
              <i class="fas fa-plus"></i>
              Add New Project Contact
            </button>
          </div>
        </div>
      </div>
    </fieldset>
    <b-modal id="bv-modal-project-contact" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <project-contact></project-contact>
    </b-modal>
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Toast from "@/utils/toast";
import MessageBox from "@/utils/messagebox";
import AddressDisplayMixin from "@/mixins/addressDisplay";
import ProjectContact from "@/views/project/components/project-contact.vue";

export default {
  name: "project-contacts",
  mixins: [AddressDisplayMixin],
  components: { ProjectContact },
  computed: {
    ...mapGetters(["getContactTypes", "getProjectContacts", "getProvinces"])
  },
  methods: {
    editProjectContact(id) {
      this.fetchProjectContact(id).then(() => {
        this.$bvModal.show("bv-modal-project-contact");
      });
    },
    async deleteProjectContact(id) {
      let confirm = await MessageBox.confirm(
        this,
        "Remove Contact",
        "Are you sure you want to remove contact?"
      );

      if (confirm) {
        this.removeProjectContact(id).then(() => {
          Toast.success(this, "Successfully removed contact!");
        });
      }
    },
    getContactTypeDisplay: function(id) {
      let contactType = this.getContactTypes.find(
        contactType => contactType.id === id
      );
      return (contactType && contactType.name) || "";
    },
    newProjectContact() {
      this.resetProjectContact().then(() => {
        this.$bvModal.show("bv-modal-project-contact");
      });
    },
    ...mapActions([
      "fetchProjectContact",
      "removeProjectContact",
      "resetProjectContact"
    ])
  }
};
</script>
