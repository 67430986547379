<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">New Follow Up Task</h5>
      </div>
      <div class="modal-body">

        <div class="form-group">
          <label for="details">Text <span class="red-dot ml-3"></span></label>
          <input id="details" name="details" type="text" class="form-control" :class="{ 'is-invalid': errors.has('details') }"
            autocomplete="off" placeholder="Enter task details" v-validate="'required'" v-model="task.text" data-vv-as="Text" />
          <div class="invalid-feedback">The Text field is required</div>
        </div>

        <div class="form-group">
          <label for="taskDueDate">Due Date <span class="red-dot ml-3"></span></label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text bg-transparent pr-2">
                <img src="@/assets/cal.svg" alt />
              </span>
            </div>
            <date-picker id="taskDueDate" name="taskDueDate" class="form-control date-picker-input" :class="{ 'is-invalid': errors.has('taskDueDate') }"
              autocomplete="off" v-model="task.dueDate" :config="datepickerOptions" v-validate="'required'" data-vv-as="Due Date">
            </date-picker>
            <div class="input-group-append">
              <span class="input-group-text">{{ timeZoneCode }}</span>
            </div>
            <div class="invalid-feedback">The Due Date field is required</div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DatePicker from "vue-bootstrap-datetimepicker";
import Toast from "@/utils/toast";

export default {
  name: "project-task",
  components: { DatePicker },
  props: ["timeZoneCode"],
  data: function() {
    return {
      task: {
        id: null,
        dueDate: null,
        isCompleted: false,
        text: null
      },
      datepickerOptions: {
        useCurrent: false,
        sideBySide: true
      }
    };
  },
  computed: {
    ...mapGetters(["getProjectTask"])
  },
  mounted: function() {
    this.task = { ...this.getProjectTask };

    if (this.task.dueDate) {
      this.task.dueDate = new Date(this.task.dueDate);
    }

    this.$validator.reset();
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.saveProjectTask(this.task).then(() => {
        let message = this.$createElement('span', {}, ['Successfully updated!', this.$createElement('br'), 'Please remember to save your project.']);
        Toast.success(this, [message]);
        this.$bvModal.hide("bv-modal-project-task");
      });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-project-task");
    },
    ...mapActions(["saveProjectTask"])
  }
};
</script>
