<template>
  <fragment>
    <div class="modal-body">
      <div class="upload-container">
        <div class="upload">
          <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
            <h3>Drop files to upload</h3>
          </div>
          <div class="text-center p-5" v-if="!files.length">
            <h3> Drop Files Anywhere To Upload </h3> <br />
            <i class="fas fa-cloud-upload-alt fa-5x"></i>
          </div>
            <table class="table table-hover" v-else>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Size</th>
                  <th>Speed</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(file, index) in files" :key="file.id">
                  <td>{{index}}</td>
                  <td>
                    <div class="filename">{{file.name}}</div>
                    <div class="progress" v-if="file.active || file.progress !== '0.00'">
                      <div
                        :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}"
                        role="progressbar"
                        :style="{width: file.progress + '%'}"
                      >{{file.progress}}%</div>
                    </div>
                  </td>
                  <td>{{file.size }}</td>
                  <td>{{file.speed }}</td>
                  <td v-if="file.error">{{ file.error }}</td>
                  <td v-else-if="file.success">success</td>
                  <td v-else-if="file.active">active</td>
                  <td v-else>&nbsp;</td>
                  <td>
                    <div class="btn-group">
                      <button class="btn btn-secondary btn-sm dropdown-toggle" type="button">Action</button>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a :class="{'dropdown-item': true, disabled: !file.active}" href="#"
                          @click.prevent="file.active ? $refs.upload.update(file, {error: 'cancel'}) : false">Cancel</a>
                        <a class="dropdown-item" href="#" v-if="file.active"
                          @click.prevent="$refs.upload.update(file, {active: false})">Abort</a>
                        <a class="dropdown-item" href="#" v-else-if="file.error && file.error !== 'compressing' && $refs.upload.features.html5"
                          @click.prevent="$refs.upload.update(file, {active: true, error: '', progress: '0.00'})">Retry upload</a>
                        <a :class="{'dropdown-item': true, disabled: file.success || file.error === 'compressing'}" href="#"
                          v-else @click.prevent="file.success || file.error === 'compressing' ? false : $refs.upload.update(file, {active: true})">Upload</a>
                        <div class="dropdown-divider"></div>
                        <a :class="{ 'dropdown-item': true, disabled: file.success }" href="#" @click.prevent="$refs.upload.remove(file)">Remove</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          <div>
            <div class="btn-group">
              <file-upload
                class="btn btn-primary dropdown-toggle"
                :post-action="postAction"
                :chunk-enabled="true"
                :chunk="{
                  action: chunkAction,
                  minSize: 1024 * 1024 * 10,
                  maxActive: 1,
                  maxRetries: 5,
                  headers: getUploadHeaders(),
                  startBody: projectUploadParams
                }"
                :extensions="getAllowedExtensions"
                :accept="getAllowedFileTypes"
                :multiple="multiple"
                :directory="directory"
                :size="1024 * 1024 * 500"
                :headers="getUploadHeaders()"
                :data="projectUploadParams"
                :drop="'.drop-active'"
                :drop-directory="true"

                v-model="files"
                ref="upload"
              >
                <i class="fa fa-plus"></i>
                Select
              </file-upload>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" @click.prevent="onAddFile">Add Files</a>
                <a class="dropdown-item" href="#" @click.prevent="onAddFolder">Add folder</a>
              </div>
            </div>
            <button type="button" class="btn btn-success" v-if="!$refs.upload || !$refs.upload.active"
                @click.prevent="$refs.upload.active = true" :disabled="!files || !files.length">
              <i class="fa fa-arrow-up" aria-hidden="true"></i>
              Start Upload
            </button>
            <button type="button" class="btn btn-danger" v-else @click.prevent="$refs.upload.active = false">
              <i class="fa fa-stop" aria-hidden="true"></i>
              Stop Upload
            </button>
          </div>
          <small>Allowed File Types: {{ getAllowedExtensions }}</small>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </fragment>
</template>

<style scoped>
.upload-container .btn-group .dropdown-menu {
  display: block;
  visibility: hidden;
  transition: all 0.2s;
}
.upload-container .btn-group:hover > .dropdown-menu {
  visibility: visible;
}

.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>

<script>
import { mapGetters } from "vuex";
import JwtHelper from "@/utils/jwtHelper";
import FileUpload from "vue-upload-component";

export default {
  name: "project-documents-upload",
  components: { FileUpload },
  props: {
    projectId: { type: String, required: true },
    currentFolderId: { type: String, required: true }
  },
  data: function() {
    return {
      files: [],
      postAction: process.env.VUE_APP_API_URL + "/api/v1/upload/post",
      chunkAction: process.env.VUE_APP_API_URL + "/api/v1/upload/chunk",
      multiple: true,
      directory: false,
      projectUploadParams: {
        projectId: null,
        folderId: null
      },
      addIndex: false,
      addData: {
        show: false,
        name: "",
        type: "",
        content: ""
      }
    };
  },
  mounted() {
    this.projectUploadParams.projectId = this.projectId;
    this.projectUploadParams.folderId = this.currentFolderId;
  },
  computed: {
    getAllowedExtensions() {
      return ["dwg", "dwf", "tiff", "jpeg", "jpg", "pdf", "doc", "docx", "xls", "xlsx", "xlsm", "ppt", "pptx",
        "mp4", "mpeg", "mpg", "avi", "wma", "wav", "mov", "heic", "png", "zip"
      ].join(", ");
    },
    getAllowedFileTypes() {
      return [".dwg", ".dwf", ".tiff", ".jpeg", ".jpg", ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".xlsm", ".ppt", ".pptx",
        ".mp4", ".mpeg", ".mpg", ".avi", ".wma", ".wav", ".mov", ".heic", ".png", ".zip"
      ].join(", ");
    },
    uploadApiEndpoint() {
      return process.env.VUE_APP_API_URL + "/api/v1/upload/post";
    },
    ...mapGetters(["getClientApiKey"])
  },
  methods: {
    closeModal() {
      if (this.files.length) {
        this.$emit("file-uploaded");
      }
      this.$bvModal.hide("bv-modal-project-documents-upload");
    },
    getUploadHeaders() {
      let headers = {};

      let clientApiKey = this.getClientApiKey;
      if (clientApiKey) { headers['api_key'] = clientApiKey }

      let bearerToken = JwtHelper.getBearerToken();
      headers['Authorization'] = "Bearer " + bearerToken;

      return headers;
    },
    onAddFile() {
      let input = this.$refs.upload.$el.querySelector("input");
      input.click();
    },
    onAddFolder() {
      if (!this.$refs.upload.features.directory) {
        this.alert("Your browser does not support");
        return;
      }
      let input = this.$refs.upload.$el.querySelector("input");
      input.directory = true;
      input.webkitdirectory = true;
      this.directory = true;
      input.onclick = null;
      input.click();
      input.onclick = () => {
        this.directory = false;
        input.directory = false;
        input.webkitdirectory = false;
      };
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!new RegExp(".(dwg|dwf|tiff|jpeg|jpg|pdf|doc|docx|xls|xlsx|xlsm|ppt|pptx|mp4|mpeg|mpg|avi|wma|wav|mov|heic|png|zip)$").test(newFile.name)) {
          return prevent();
        }
      }
    }
  }
};
</script>
