<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row form-title d-flex align-items-center mb-4">
        <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">3</span>
        <h2 class="m-0">Project Description</h2>
      </div>

      <div class="form-group">
        <label for="tenderStageId">Tender Stage <span class="red-dot ml-3" v-if="requiredForPrebid"></span></label>
        <select id="tenderStageId" name="tenderStageId" class="custom-select" :class="{ 'is-invalid': errors.has('tenderStageId') }"
                v-validate="{ required: requiredForPrebid }" v-model="project.tenderStageId"
                :readonly="project.fromBids" :disabled="project.fromBids" data-vv-as="Tender Stage">
          <option v-for="tender in getTenderStages" :key="tender.id" :value="tender.id">{{ tender.name }}</option>
        </select>
        <div class="invalid-feedback">The Tender Stage field is required</div>
      </div>

      <label>Funding <span class="red-dot ml-3" v-if="requiredForPrebid"></span> </label>
      <div class="mb-3">
        <div v-for="(funding, index) in getFundingTypes" :key="'funding-container-' + index" class="custom-control custom-radio">
          <input :id="'funding-item-' + index" name="funding" type="radio" class="custom-control-input" :class="{ 'is-invalid': errors.has('funding') }"
                 :value="funding.id" v-model="project.fundingId" v-if="index === 0" v-validate="{ required: requiredForPrebid }" data-vv-as="Funding">
          <input :id="'funding-item-' + index" name="funding" type="radio" class="custom-control-input" :class="{ 'is-invalid': errors.has('funding') }"
                 :value="funding.id" v-model="project.fundingId" v-else>
          <label class="custom-control-label" :for="'funding-item-' + index">{{ funding.name }}</label>
          <div v-if="index === getFundingTypes.length - 1" class="invalid-feedback">The Funding field is required</div>
        </div>
      </div>

      <div class="form-group">
        <label for="procurementTypeId">Procurement Type <span class="red-dot ml-3" v-if="requiredForOpenProject"></span></label>
        <select id="procurementTypeId" name="procurementTypeId" class="custom-select" :class="{ 'is-invalid': errors.has('procurementTypeId') }"
                v-validate="{ required: requiredForOpenProject }" v-model="project.procurementTypeId" data-vv-as="Procurement Type">
          <option v-for="procurementType in getProcurementTypes" :key="procurementType.id" :value="procurementType.id">{{ procurementType.name }}</option>
        </select>
        <div class="invalid-feedback">The Procurement Type field is required</div>
      </div>

      <label>Classification Type  <span class="red-dot ml-3" v-if="requiredForOpenProject"></span></label>
      <div class="d-flex" :class="{ 'mb-3' : (!project.classificationTypeId || project.classificationTypeId && getClassificationOfWork.length === 0) }">
        <div v-for="(classification, index) in getClassificationTypes" :key="'classification-type-container-' + index" class="custom-control custom-radio custom-control-inline">
          <input :id="'classification-type-item-' + index" name="classificationType" type="radio" class="custom-control-input"
                 :class="{ 'is-invalid': errors.has('classificationType') }" :value="classification.id" v-model="project.classificationTypeId"
                 v-if="index === 0" v-validate="{ required: requiredForOpenProject }" data-vv-as="Classification Type">
          <input :id="'classification-type-item-' + index" name="classificationType" type="radio" class="custom-control-input"
                 :class="{ 'is-invalid': errors.has('classificationType') }" :value="classification.id" v-model="project.classificationTypeId"
                 v-else>
          <label class="custom-control-label" :for="'classification-type-item-' + index">{{ classification.name }}</label>
          <div v-if="index === getFundingTypes.length - 1" class="invalid-feedback">The Classification Type field is required</div>
        </div>
      </div>

      <div id="project-classification-of-works-container" v-if="project.classificationTypeId && getClassificationOfWork.length > 0">
        <div v-for="(work, index) in getClassificationOfWork" :key="'classification-work-container-' + index" class="custom-control custom-checkbox">
          <input :id="'classification-work-item-' + index" type="checkbox" class="custom-control-input" :value="work.id" v-model="project.classificationOfWorkIds">
          <label class="custom-control-label" :for="'classification-work-item-' + index">{{ work.name }}</label>
        </div>
      </div>

      <div class="form-group">
        <label for="ownerTypeId">Owner Type <span class="red-dot ml-3" v-if="requiredForPrebid"></span></label>
        <select id="ownerTypeId" name="ownerTypeId" class="custom-select" :class="{ 'is-invalid': errors.has('ownerTypeId') }"
                v-validate="{ required: requiredForPrebid }" v-model="project.ownerTypeId" data-vv-as="Owner Type">
          <option v-for="ownerType in getOwnerTypes" :key="ownerType.id" :value="ownerType.id">{{ ownerType.name }}</option>
        </select>
        <div class="invalid-feedback">The Owner Type field is required</div>
      </div>

      <div class="form-group">
        <label for="budget">Budget</label>
        <input id="budget" name="budget" type="text" class="form-control" :class="{ 'is-invalid': errors.has('budget') }"
               autocomplete="off" placeholder="Enter amount" v-model="project.budget" v-validate="'numeric'" data-vv-as="Budget" />
        <div class="invalid-feedback">The Budget field must be numeric</div>
      </div>

      <div class="form-group">
        <label for="scopeOfWork">Scope Of Work <span class="red-dot ml-3" v-if="requiredForPrebid"></span></label>
        <app-editor id="scopeOfWork" name="scopeOfWork" :class="{ 'input-validation-error': errors.has('scopeOfWork') }"
                    v-validate="{ required: requiredForPrebid }" data-vv-as="Scope of Work" v-model="project.scopeOfWork" :readonly="project.fromBids">
        </app-editor>
        <div class="invalid-feedback">The Scope of Work field is required</div>
      </div>

      <div class="form-group">
        <label for="comments">Comments</label>
        <app-editor id="comments" name="comments" v-model="project.comments"></app-editor>
      </div>

      <div class="form-group">
        <label for="disclaimer">Disclaimer</label>
        <app-editor id="disclaimer" name="disclaimer" v-model="project.disclaimer"></app-editor>
      </div>

      <div class="form-group">
        <label for="tradeAgreementIds">Applicable Trade Agreements</label>
        <v-select id="tradeAgreementIds" name="tradeAgreementIds" placeholder="Select applicable trade agreements" class="v-select-multi"
                  multiple :closeOnSelect="false" label="name" :reduce="tradeAgreement => tradeAgreement.id"
                  :options="getTradeAgreements" v-model="project.tradeAgreementIds">
        </v-select>
      </div>


      <div class="form-group form-check custom-control custom-checkbox">
        <input id="privateProject" type="checkbox" class="custom-control-input" :value="true" v-model="project.isPrivate"
               :disabled="forceAsPrivate" :readonly="forceAsPrivate">
        <label for="privateProject" class="custom-control-label">
          Set As Private
        </label>
      </div>
      <div class="form-group" v-if="!project.isPrivate">
        <div class="custom-control custom-radio">
          <input id="showProject" name="showProject" type="radio" class="custom-control-input" :value="false" v-model="project.showProject">
          <label class="custom-control-label" for="showProject">Hide Project</label>
        </div>
        <div class="custom-control custom-radio mb-3">
          <input id="hideProject" name="showProject" type="radio" class="custom-control-input" :value="true" v-model="project.showProject">
          <label class="custom-control-label" for="hideProject">Show Project</label>
        </div>
      </div>

      <div v-if="!project.isPrivate" class="form-group form-check custom-control custom-checkbox">
        <input id="enable-i-am-bidding-feature" type="checkbox" class="custom-control-input" :value="true" v-model="project.enableRequestToBid">
        <label for="enable-i-am-bidding-feature" class="custom-control-label">
          Enable "I Am Bidding" Feature
        </label>
      </div>


    </div>
    <div class="col-md d-none d-lg-block"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isDraftMode, isPreBid, isPrivateProject, requiredForOpenProject, requiredForPrebid } from "@/mixins/projectStatuses";
import AppEditor from "@/components/app-editor";

export default {
  name: "ProjectDescription",
  inject: ["$validator"],
  components: { AppEditor },
  props: ["project"],
  mixins: [isDraftMode, isPreBid, isPrivateProject, requiredForOpenProject, requiredForPrebid],
  computed: {
    forceAsPrivate () {
      return !this.$permissions().isSysAdmin && !this.$permissions().isClientAdmin && this.$permissions().hasAddPrivateProjectPermission;
    },
    getClassificationOfWork() {
      let selectedClassificationType = this.getClassificationTypes.find(type => type.id === this.project.classificationTypeId);
      return selectedClassificationType ? selectedClassificationType.classificationOfWorks.sort((a,b) => a.name.localeCompare(b.name)) : [];
    },
    ...mapGetters([
      "getClassificationTypes",
      "getFundingTypes",
      "getOwnerTypes",
      "getProcurementTypes",
      "getTenderStages",
      "getTradeAgreements"
    ])
  },
  mounted () {
    if (this.forceAsPrivate && !!this.$route.params.id === false) {
      this.project.isPrivate = true;
    }
  }
};
</script>
