<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h4 class="modal-title">Site Meeting</h4>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="meetingDetails">Site Meeting Information <span class="red-dot ml-3"></span></label>
          <textarea id="meetingDetails" name="meetingDetails" class="form-control" :class="{ 'is-invalid': errors.has('meetingDetails') }"
            rows="5" placeholder="Enter meeting information" v-validate="'required'" v-model="meeting.text" data-vv-as="Site Meeting Information">
          </textarea>
          <div class="invalid-feedback">The Site Meeting Information field is required</div>
        </div>

        <div class="form-group">
          <label for="meetingPriority">Meeting Type <span class="red-dot ml-3"></span></label>
          <select id="meetingPriority" name="meetingPriority" class="custom-select" :class="{ 'is-invalid': errors.has('meetingPriority') }"
              v-validate="'required'" v-model="meeting.priority" data-vv-as="Priority">
            <option value="OPTIONAL" selected>Optional</option>
            <option value="MANDATORY">Mandatory</option>
            <option value="RECOMMENDED">Recommended</option>
          </select>
          <div class="invalid-feedback">The Meeting Type field is required</div>
        </div>

        <div class="form-group">
          <label for="meetingDueDate">Meeting Date <span class="red-dot ml-3"></span></label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text bg-transparent pr-2">
                <img src="@/assets/cal.svg" alt />
              </span>
            </div>
            <date-picker id="meetingDueDate" name="meetingDueDate" class="form-control date-picker-input" :class="{ 'is-invalid': errors.has('meetingDueDate') }"
              autocomplete="off" v-model="meeting.date" :config="datepickerOptions" v-validate="'required'">
            </date-picker>
            <div class="input-group-append">
              <span class="input-group-text">{{ timeZoneCode }}</span>
            </div>
            <div class="invalid-feedback">The Meeting Date field is required</div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";
import DatePicker from "vue-bootstrap-datetimepicker";

export default {
  name: "project-site-meeting",
  components: { DatePicker },
  props: ["timeZoneCode"],
  data: function() {
    return {
      meeting: {
        id: null,
        text: null,
        priority: null,
        date: null
      },
      datepickerOptions: {
        useCurrent: false,
        sideBySide: true
      }
    };
  },
  computed: {
    ...mapGetters(["getProjectSiteMeeting"])
  },
  mounted: function() {
    this.meeting = { ...this.getProjectSiteMeeting };
    if (this.meeting.date) {
      this.meeting.date = new Date(this.meeting.date);
    }

    this.$validator.reset();
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.saveProjectSiteMeeting(this.meeting).then(() => {
        let message = this.$createElement('span', {}, [ 'Successfully updated!', this.$createElement('br'), 'Please remember to save your project.']);
        Toast.success(this, [message]);
        this.$bvModal.hide("bv-modal-project-site-meeting");
      });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-project-site-meeting");
    },
    ...mapActions(["saveProjectSiteMeeting"])
  }
};
</script>
